import React from 'react';
import {
		Alert,
		Backdrop,
		Button,
		Card,
		CardContent,
		CardMedia,
		CircularProgress,
		Grid,
		Paper,
		Typography
} from '@mui/material';;
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { auth, db, storage, firestore } from '../../firebase-info';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BlockIcon from '@mui/icons-material/Block';

const inputStyle = {
	width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '32px',
}
const formContainer = {
	width: '100%',
	marginTop: '32px',
}

export default class PapUserProfile extends React.Component {
	state = {
		open: false,
		isUploading: false,
		loading: true,
		hasProfileCompleted: false,
		imgToDisplay: '',
		orgSnapshot: '',
		orgRef: '',
		orgName: '',
		email: '',
		contactPerson: '',
		address: '',
		location: '',
		province: '',
		hasDocuments: '',
		files: [],
		pic: [],
		alertMessage: '',
		alertType: ''
	};

	blackButton = {
		fontFamily: 'Quicksand',
		fontSize: '20px',
		lineHeight: '26px',
		fontWeight: "700",
		width: '100%',
		backgroundColor: (Boolean(this.state.contactPerson || this.state.address || this.state.location || this.state.province))  ? 'rgb(0,0,0,0.26)' :  '#000000',
		color: (Boolean(this.state.contactPerson || this.state.address || this.state.location || this.state.province)) ? 'rgb(255,255,255,0.26)' :'#FFFFFF',
		borderRadius: '50px',
		paddingLeft: '55px',
		paddingRight: '55px',
		paddingTop: '21px',
		paddingBottom: '21px',
		marginTop: '27px',
		marginBottom: '27px',
	}

	handleChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.value });
	};

	handleFileChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.files });
	}

	formatDate(date = new Date(), isHuman = false) {
		const dd = String(date.getDate()).padStart(2, '0');
		const mm = String(date.getMonth() + 1).padStart(2, '0');
		const yyyy = date.getFullYear();

		return isHuman ? dd + '-' + mm + '-' + yyyy :  yyyy + '-' + mm + '-' + dd;
	}

	handleLoadingClose = () => {
	 this.setState({ ...this.state, open:  false });
	};
	handleLoadingToggle = () => {
		this.setState({ ...this.state, open:  !this.state.open });
	};

	async componentDidMount() {
		const userRef = await db.collection(`users`).doc(auth.currentUser.uid);
		const userSnapshot = await userRef.get();
		const orgRef = userSnapshot.get('orgRef');
		const orgSnapshot = await orgRef.get();
		const imgToDisplay = orgSnapshot.get('imgDownloadUrl')
		const hasProfileCompleted =  Boolean(orgSnapshot.get('contactPerson') && orgSnapshot.get('location') && orgSnapshot.get('address') && orgSnapshot.get('province'));

		this.setState({ ...this.state, orgSnapshot: orgSnapshot});
		this.setState({ ...this.state, orgRef: orgRef});
		this.setState({ ...this.state, imgToDisplay: imgToDisplay});
		this.setState({ ...this.state, orgName: orgSnapshot.get('displayName')});
		this.setState({ ...this.state, email: auth.currentUser.email});
		this.setState({ ...this.state, contactPerson: orgSnapshot.get('contactPerson')});
		this.setState({ ...this.state, location: orgSnapshot.get('location')});
		this.setState({ ...this.state, address: orgSnapshot.get('address')});
		this.setState({ ...this.state, province: orgSnapshot.get('province')});
		this.setState({ ...this.state, hasDocuments: orgSnapshot.get('hasDocuments')});
		this.setState({ ...this.state, loading: false});
		this.setState({ ...this.state, hasProfileCompleted})
	}

	async uploadDocuments(files){
		const cif =  this.state.orgSnapshot.get('cif');
		let today = this.formatDate();
		const orgRef = this.state.orgRef;

		for (let i = 0; i < files.length; i++) {
			const name = `${today}_${files[i].name}`
			const storageRef = storage.ref();
			const fileRef = storageRef.child(`documents/orgDocuments/${cif}/${name}`);
			const that = this;

			let task = fileRef.put(files[i]);
			task.on('state_changed',
				function progress(snapshot) {},
					function error(err) {
						that.handleLoadingClose();
						that.setState({ ...this.state, alertMessage: 'Error al subir los documentos: '+ err.message, alertType: "error"});
						window.scrollTo(0, 0);
					},
					async function complete() {
						const docUrl = await fileRef.getDownloadURL();

						orgRef.update({
							documents: firestore.FieldValue.arrayUnion(docUrl)
						});

						that.handleLoadingClose();
						that.setState({ ...this.state, alertMessage: 'Documentos subidos correctamente, los validaremos lo antes posible', alertType: "success"});
						window.scrollTo(0, 0)
					}
			);      
		}
	}

	handleSubmit = async () => {
		this.handleLoadingToggle();
		
		const files = this.state.files;

		if (!files.length) {
			this.handleLoadingClose();
		}
	 
		await this.uploadDocuments(files);
		
	}

	handleSubmitLogo = async () => {
		this.handleLoadingToggle();
		const cif =  this.state.orgSnapshot.get('cif');
		const storageRef = storage.ref();
		const fileRef = storageRef.child(`images/organizationsProfilePics/${cif}`);
		if (!this.state.pic[0]) {
				this.handleLoadingClose();
				return;
		}
		try {
			const snapshotImg = await fileRef.put(this.state.pic[0]);
			const imageUrl = `gs://${snapshotImg.metadata.bucket}/${snapshotImg.metadata.fullPath}`;
			const imgDownloadUrl = await storage.refFromURL(imageUrl).getDownloadURL();
			await this.state.orgRef.update({
				imageUrl,
				imgDownloadUrl,
			});
			this.setState({ ...this.state, imgToDisplay: imgDownloadUrl});
		} finally {
			this.handleLoadingClose();
		}
	}

	handleSubmitOrgInformation = async () => {
		this.handleLoadingToggle();
		try {
			await this.state.orgRef.update({
				contactPerson: this.state.contactPerson,
				address: this.state.address,
				location: this.state.location,
				province: this.state.province,
			});
			this.setState({ ...this.state, hasProfileCompleted: true });
			this.handleLoadingClose();
		} catch {
			this.setState({ ...this.state, contactPerson: '' });
			this.setState({ ...this.state, location: '' });
			this.setState({ ...this.state, address: '' });
			this.setState({ ...this.state, province: '' });
			this.handleLoadingClose();
		}
	}

	render() {
		const {
			imgToDisplay,
			orgName,
			email,
			contactPerson,
			address,
			location,
			province,
			hasDocuments,
			hasProfileCompleted,
			loading,
			alertMessage,
			alertType,
		} = this.state;

		return (
			<div style={{ width: '80%', paddingTop: '26px', paddingBottom: '26px', margin: '0 auto', borderRadius: '16px' }}>
				{alertMessage && <Alert severity={alertType}>{alertMessage}</Alert> }
				{loading ?
					<>
						<div style={{display: 'flex', alignContent: 'center'}}>
							<CircularProgress color="primary" />
						</div>
					</>
					:
					<>
					<Backdrop open={this.state.open}>
						<CircularProgress color="inherit" />
					</Backdrop>
					<Card style={{ paddingTop: '26px', paddingBottom: '26px',  }}>
						<CardMedia title={orgName} image={imgToDisplay} component="img" style={{margin: '0 auto', height: 140, objectFit: 'contain'}} />
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Email:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{email}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Contacto:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{contactPerson}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Organización:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{orgName}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Dirección:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{address}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Localidad:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{location}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Provincia:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography fontFamily={'Quicksand'}>
										{province}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
										Documentos verificados:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									{ hasDocuments ? <DoneAllIcon style={{color: 'green'}}/> : <BlockIcon style={{color: 'red'}}/>}
								</Grid>
								<Grid item xs={8}>
									{!hasProfileCompleted &&
										<ValidatorForm
											ref="form"
											style={formContainer}
											onSubmit={this.handleSubmitOrgInformation}
											onError={errors => console.log(errors)}
										>
											<TextValidator
												label="Email de la persona de contacto"
												onChange={this.handleChange('contactPerson')}
												name="contactPersonRegister"
												style={inputStyle}
												value={contactPerson}
											/>
											<TextValidator
												label="Dirección social de la organización"
												onChange={this.handleChange('address')}
												name="addressRegister"
												style={inputStyle}
												value={address}
											/>
											<TextValidator
												label="Localidad"
												onChange={this.handleChange('location')}
												name="locationRegister"
												style={inputStyle}
												value={location}
											/>
											<TextValidator
												label="Provincia"
												onChange={this.handleChange('province')}
												name="provinceRegister"
												style={inputStyle}
												value={province}
											/>
											<Button variant="contained" disabled={(!(contactPerson || address || location || province))} type="submit"  style={this.blackButton}> Actualizar mis datos</Button>
										</ValidatorForm>
									}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Paper style={{ marginTop: '20px', padding: '15px' }}>
						{!hasDocuments && hasProfileCompleted &&
							<>
								<Typography fontFamily={'Quicksand'} variant='body2' component='p'>
									Es necesario que suban los siguientes documentos:
								</Typography>
								<ul>
									<li> Resolución administrativa de inscripción como organización (Obligatorio) </li>
									<li> Modelo 036 (Obligatorio)</li>
									<li> Justificante de estar al corriente de pagos con las administraciones públicas (Opcional)*</li>
								</ul>
								<Typography fontFamily={'Quicksand'} variant='body2' component='p'>
									Si tienes cualquier problema nos puedes escribir a <a href='mailto:info@premiumandpremium.com'>info@premiumandpremium.com</a>
								</Typography>
								<Typography fontFamily={'Quicksand'} variant='body2' component='p'>
									*En caso de no tener este documento nos podríamos ver obligados a retener los fondos de la organización en caso de requerimiento de la Hacienda Pública.
								</Typography>
								<ValidatorForm
									ref="documentForm"
									style={{width: '100%'}}
									onSubmit={this.handleSubmit}
									onError={errors => console.log(errors)}
								>
								<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
												Subir documentos acreditativos:
									</Typography>
									<input
										label="Documentos acreditativos"
										onChange={this.handleFileChange('files')}
										name="files"
										type="file"
										style={{width: '100%', marginTop: '15px', marginBottom: '15px', textTransform: 'capitalize', justifyContent: 'left'}}
										multiple
									/>
									<Button variant="outlined" color="primary" type="submit"  style={{width: '100%', marginTop: '15px', marginBottom: '15px' }}>Subir documentos</Button>
								</ValidatorForm>
							</>
						}
						<ValidatorForm
							ref="LogoForm"
							style={{width: '100%'}}
							onSubmit={this.handleSubmitLogo}
							onError={errors => console.log(errors)}
						>
						<Typography fontFamily={'Quicksand'} color='textSecondary' style={{paddingLeft: '10px'}}>
							Subir o modificar logo:
						</Typography>
						<input
							label="Logo"
							onChange={this.handleFileChange('pic')}
							name="pic"
							type="file"
							style={{width: '100%', marginTop: '15px', marginBottom: '15px', textTransform: 'capitalize', justifyContent: 'left', fontFamily: 'Quicksand'}}
							multiple
						/>
						<Button variant="outlined" color="primary" type="submit"  style={{width: '100%', marginTop: '15px', marginBottom: '15px', fontFamily: 'Quicksand' }}>Subir Logo</Button>
						</ValidatorForm>
					</Paper>
				</>}
			</div>
		);
	}
}